import React from 'react';
// import sections
import Hero from '../components/sections/Hero';
import FeaturesTiles from '../components/sections/FeaturesTiles';
import FeaturesSplit from '../components/sections/FeaturesSplit';
import Features from '../components/sections/Features';
import Mining from '../components/sections/Mining';
import Testimonial from '../components/sections/Testimonial';
import Cta from '../components/sections/Cta';
global.CLAIM_DROP_TIME = new Date(1641542400 * 1000)

const Home = () => {

  return (
    <>
      <Hero className="illustration-section-01" />
      <FeaturesSplit invertMobile topDivider imageFill className="illustration-section-02" />
      <Testimonial topDivider />
      {/*<Features invertMobile topDivider imageFill className="illustration-section-02" />*/}
      <Mining invertMobile topDivider imageFill className="illustration-section-02" />
      <FeaturesTiles />

      {/*<Cta split />*/}
    </>
  );
}

export default Home;
