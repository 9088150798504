import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';
import Button from '../elements/Button';

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}
const FeaturesTiles = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {

  const outerClasses = classNames(
    'features-tiles section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-tiles-inner section-inner pt-0',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const tilesClasses = classNames(
    'tiles-wrap center-content',
    pushLeft && 'push-left'
  );

  const sectionHeader = {
    title: 'OUR COMMUNITIES',
    paragraph: 'Communities are immutable! Communities are owned by the creator and cannot be deleted by anyone but the creator. Information about a community is either stored on the blockchain or a distributed ledger (For example Ceramic/Orbitdb).'
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={tilesClasses}>

            <a href="https://peakd.com/c/hive-148441">
              <div className="tiles-item reveal-from-bottom">
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-header">
                    <div className="features-tiles-item-image mb-16">
                      <Image
                        src={require('./../../assets/images/gem.png')}
                        alt="Features tile icon 01"
                        width={120}
                        height={64} />
                    </div>
                  </div>
                  <div className="features-tiles-item-content">
                    <h4 className="mt-0 mb-8">
                      GEM
                    </h4>
                    <p className="m-0 text-sm">
                      
                    </p>
                  </div>
                </div>
              </div>
            </a>
            <a href="https://leofinance.io">
              <div className="tiles-item reveal-from-bottom" data-reveal-delay="200">
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-header">
                    <div className="features-tiles-item-image mb-16">
                      <Image
                        src={require('./../../assets/images/leo.png')}
                        alt="Features tile icon 02"
                        width={120}
                        height={64} />
                    </div>
                  </div>
                  <div className="features-tiles-item-content">
                    <h4 className="mt-0 mb-8">
                      Leo Finance
                    </h4>
                    <p className="m-0 text-sm">
                    </p>
                  </div>
                </div>
              </div>
            </a>

            <a href="https://peakd.com/c/hive-140217">
              <div className="tiles-item reveal-from-bottom" data-reveal-delay="400">
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-header">
                    <div className="features-tiles-item-image mb-16">
                      <Image
                        src={require('./../../assets/images/hive.png')}
                        alt="Features tile icon 03"
                        width={120}
                        height={64} />
                    </div>
                  </div>
                  <div className="features-tiles-item-content">
                    <h4 className="mt-0 mb-8">
                      HIVE Gaming
                    </h4>
                    <p className="m-0 text-sm">
                      
                    </p>
                  </div>
                </div>
              </div>
            </a>

            <a href="https://peakd.com/c/hive-156509">
              <div className="tiles-item reveal-from-bottom">
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-header">
                    <div className="features-tiles-item-image mb-16">
                      <Image
                        src={require('./../../assets/images/chain.jfif')}
                        alt="Features tile icon 04"
                        width={120}
                        height={64} />
                    </div>
                  </div>
                  <div className="features-tiles-item-content">
                    <h4 className="mt-0 mb-8">
                      OnChain Art
                    </h4>
                    <p className="m-0 text-sm">
                      
                    </p>
                  </div>
                </div>
              </div>
            </a>

            <a href="https://peakd.com/c/hive-105017">
              <div className="tiles-item reveal-from-bottom" data-reveal-delay="200">
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-header">
                    <div className="features-tiles-item-image mb-16">
                      <Image
                        src={require('./../../assets/images/hivecn.png')}
                        alt="Features tile icon 05"
                        width={120}
                        height={64} />
                    </div>
                  </div>
                  <div className="features-tiles-item-content">
                    <h4 className="mt-0 mb-8">
                      HIVE CN
                    </h4>
                    <p className="m-0 text-sm">
                      
                    </p>
                  </div>
                </div>
              </div>
            </a>

            <a href="https://peakd.com/c/hive-114105">
              <div className="tiles-item reveal-from-bottom" data-reveal-delay="400">
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-header">
                    <div className="features-tiles-item-image mb-16">
                      <Image
                        src={require('./../../assets/images/lifestyle.png')}
                        alt="Features tile icon 06"
                        width={120}
                        height={64} />
                    </div>
                  </div>
                  <div className="features-tiles-item-content">
                    <h4 className="mt-0 mb-8">
                      The lifestyle Lounge
                    </h4>
                    <p className="m-0 text-sm">
                      
                    </p>
                  </div>
                </div>
              </div>
            </a>

            <a href="https://peakd.com/c/hive-181335">
              <div className="tiles-item reveal-from-bottom" data-reveal-delay="400">
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-header">
                    <div className="features-tiles-item-image mb-16">
                      <Image
                        src={require('./../../assets/images/logo.png')}
                        alt="Features tile icon 06"
                        width={120}
                        height={64} />
                    </div>
                  </div>
                  <div className="features-tiles-item-content">
                    <h4 className="mt-0 mb-8">
                      Threespeak
                    </h4>
                    <p className="m-0 text-sm">
                      
                    </p>
                  </div>
                </div>
              </div>
            </a>


            <a href="https://peakd.com/c/hive-122315">
              <div className="tiles-item reveal-from-bottom" data-reveal-delay="400">
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-header">
                    <div className="features-tiles-item-image mb-16">
                      <Image
                        src={require('./../../assets/images/deep.jfif')}
                        alt="Features tile icon 06"
                        width={120}
                        height={64} />
                    </div>
                  </div>
                  <div className="features-tiles-item-content">
                    <h4 className="mt-0 mb-8">
                      Deep Dives
                    </h4>
                    <p className="m-0 text-sm">
                      
                    </p>
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
          <center>
            <Button tag="a" color="dark" wideMobile href="https://3speak.tv/communities">
              More Communities
            </Button>
          </center>
      </div>
    </section>
  );
}

FeaturesTiles.propTypes = propTypes;
FeaturesTiles.defaultProps = defaultProps;

export default FeaturesTiles;