import React from 'react';
// import sections
import Hero1 from '../components/sections/Hero1';
import FeaturesTiles from '../components/sections/FeaturesTiles';
import FeaturesSplit1 from '../components/sections/FeaturesSplit1';
import Features from '../components/sections/Features';
import Mining from '../components/sections/Mining';
import Testimonial from '../components/sections/Testimonial';
import Cta from '../components/sections/Cta';

const Token = () => {

    return (
        <>
            <Hero1 className="illustration-section-01" />
            <FeaturesSplit1 invertMobile topDivider imageFill className="illustration-section-02" />

        </>
    );
}

export default Token;