import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';
import moment from 'moment'

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

const FeaturesSplit = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {

  const outerClasses = classNames(
    'features-split section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-split-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const splitClasses = classNames(
    'split-wrap',
    invertMobile && 'invert-mobile',
    invertDesktop && 'invert-desktop',
    alignTop && 'align-top'
  );

  const sectionHeader = {
    title: 'SPK Network',
    //paragraph: 'The SPK network provides the ability for web3 social media platforms to store content in a decentralized manner. If desired, they can also earn rewards in doing so.'
    paragraph: ''
  };

  const [timeVal, setTimeVal] = useState('')

  useEffect(() => {
    setInterval(() => {
      setTimeVal('')
      var diffTime = (global.CLAIM_DROP_TIME / 1) - (new Date() / 1);
      var duration = moment.duration(diffTime, 'milliseconds');
      setTimeVal(duration.days() + ' days ' + duration.hours() + " hours " + duration.minutes() + " minutes and " + duration.seconds() + ' seconds')
    }, 1000)
  }, [])

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <div style={{justifyContent: 'center', textAlign: 'center', paddingBottom: "16px"}}>
            <h2>
              Claim Drop (now over!)
            </h2>
            <h4 style={{color: 'white'}}>
              Further info available <a style={{color: "#6163FF"}}href="https://peakd.com/hive-112019/@spknetwork/call-to-action-run-an-spk-claim-chain-node-get-rewarded">
                here
              </a>
            </h4>
          </div>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={splitClasses}>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                </div>
                <h3 className="mt-0 mb-12">
                  Self Hosting
                </h3>
                <p className="m-0">
                  Your community/local group can help eachother by providing IPFS storage with your PCs.
                  This removes central points of failure unlike traditional platforms.

                </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/hosting.png')}
                  alt="Features split 01"
                  width={328}
                  height={196} />
              </div>
            </div>
            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">

                </div>
                <h3 className="mt-0 mb-12">
                  Censorship Resistant
                </h3>
                <p className="m-0">
                  Independent users running the desktop application can store content from creators. By default when the user clicks play on a video, the app will automatically start downloading the video.
                  Meaning, the content creator does not have to take on the burden of hosting the video. If one of the servers hosting the video is taken down, the content will continue to stay alive via secondary nodes storing the data.
                </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/220__VIDEO__Upload_A_Censorship_Resistant_Video____C.png')}
                  alt="Features split 01"
                  width={328}
                  height={196} />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">

                </div>
                <h3 className="mt-0 mb-12">
                  Users do not depend on YouTube
                </h3>
                <p className="m-0">
                  SPK Network puts storage, CDN, and video encoding infrastructure into the hands of peers within the community, meaning there is not a reliance on a centralised content platform (that can control your data and account of course). </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/200__VIDEO__Decentralized_Video____B.png')}
                  alt="Features split 03"
                  width={528}
                  height={396} />
              </div>

            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">

                </div>
                <h3 className="mt-0 mb-12">
                  Tokenise Your Communities

                </h3>
                <p className="m-0">
                  The SPK Network provides a token creation system for content creators and communities.
                  Meaning creators can have their own independent community centric token economies.
                </p></div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/330__HUB__SPKHub_Vs_YT____C.png')}
                  alt="Features split 02"
                  width={528}
                  height={396} />
              </div>
            </div>

            {/*<div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">

                </div>
                <h3 className="mt-0 mb-12">
                  Community Driven Tokens
                </h3>
                <p className="m-0">
                  Content creators and community economies outside and independent of the system are unstoppable. <br />

                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/breakaway.png')}
                  alt="Features split 03"
                  width={528}
                  height={396} />
              </div>

                </div>*/}


            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">

                </div>
                <h3 className="mt-0 mb-12">
                  LARYNX MINER CLAIM DROP (MONTHLY)
                </h3>
                <p className="m-0">
                Each HIVE blockchain token holder is eligible to claim their LARYNX miner tokens based on the amount of HIVE and HIVE power held in their wallet on January 6th 2022. 
Unclaimed tokens will be distributed via proposal fund by community vote.   </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/larynx.png')}
                  alt="Features split 03"
                  width={528}
                  height={396} />
              </div>

            </div>


          </div>
        </div>
      </div>
    </section >
  );
}

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;
