import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const propTypes = {
    ...SectionSplitProps.types
}

const defaultProps = {
    ...SectionSplitProps.defaults
}

const Features = ({
    className,
    topOuterDivider,
    bottomOuterDivider,
    topDivider,
    bottomDivider,
    hasBgColor,
    invertColor,
    invertMobile,
    invertDesktop,
    alignTop,
    imageFill,
    ...props
}) => {

    const outerClasses = classNames(
        'features-split section',
        topOuterDivider && 'has-top-divider',
        bottomOuterDivider && 'has-bottom-divider',
        hasBgColor && 'has-bg-color',
        invertColor && 'invert-color',
        className
    );

    const innerClasses = classNames(
        'features-split-inner section-inner',
        topDivider && 'has-top-divider',
        bottomDivider && 'has-bottom-divider'
    );

    const splitClasses = classNames(
        'split-wrap',
        invertMobile && 'invert-mobile',
        invertDesktop && 'invert-desktop',
        alignTop && 'align-top'
    );

    const sectionHeader = {
        title: 'Store content independantly',
        //paragraph: 'The SPK Network provides a mechanism to operate low cost, easy to run, distributed storage, and indexing nodes maintained by members of the community.'
    };

    return (
        <section
            {...props}
            className={outerClasses}
        >
            <div className="container">
                <div className={innerClasses}>
                    <SectionHeader data={sectionHeader} className="center-content" />
                    <div className={splitClasses}>

                        {/*<div className="split-item">
                            <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">

                                </div>
                                <h3 className="mt-0 mb-12">
                                    Censorship Resistant
                                </h3>
                                <p className="m-0">
                                    Independent users running the desktop application can store content from creators. By default when the user clicks play on a video, the app will automatically start downloading the video.
                                    Meaning, the content creator does not have to take on the burden of hosting the video. If one of the servers hosting the video is taken down, the content will continue to stay alive via secondary nodes storing the data.
                                </p>
                            </div>
                            <div className={
                                classNames(
                                    'split-item-image center-content-mobile reveal-from-bottom',
                                    imageFill && 'split-item-image-fill'
                                )}
                                data-reveal-container=".split-item">
                                <Image
                                    src={require('./../../assets/images/210.png')}
                                    alt="Features split 01"
                                    width={328}
                                    height={196} />
                            </div>
                                </div>*/}
                        <div className="split-item">
                            <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">

                                </div>
                                <h3 className="mt-0 mb-12">
                                    Platform Benefits
                                </h3>
                                <p className="m-0">
                                    The breakaway community system is designed to be blockchain/platform agnostic. 
                                    We plan to leverage our decentralized communities systems to act as a social media index for platforms to make use of.
                                    Resulting in making the integration process easier without dealing with complicated blockchains.
                                    We provide platforms with tooling for decentralized social media.
                                </p>
                            </div>
                            <div className={
                                classNames(
                                    'split-item-image center-content-mobile reveal-from-bottom',
                                    imageFill && 'split-item-image-fill'
                                )}
                                data-reveal-container=".split-item">
                                <Image
                                    src={require('./../../assets/images/550.png')}
                                    alt="Features split 01"
                                    width={328}
                                    height={196} />
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>
    );
}

Features.propTypes = propTypes;
Features.defaultProps = defaultProps;

export default Features;