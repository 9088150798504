import React, { useState } from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import ButtonGroup from '../elements/ButtonGroup';
import Button from '../elements/Button';
import Image from '../elements/Image';
import Modal from '../elements/Modal';

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

const Hero = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {

  const [videoModalActive, setVideomodalactive] = useState(false);

  const openModal = (e) => {
    e.preventDefault();
    setVideomodalactive(true);
  }

  const closeModal = (e) => {
    e.preventDefault();
    setVideomodalactive(false);
  }

  const outerClasses = classNames(
    'hero section center-content',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'hero-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container-sm">
        <div className={innerClasses}>
          <div className="hero-content">
            <h1 className="mt-0 mb-16 reveal-from-bottom" data-reveal-delay="200">
              An incentivization layer for <span className="text-color-primary">web3</span>
            </h1>
            <div className="container-sm">
              <Image
                src={require('./../../assets/images/homes1.svg')}
                alt="Features split 02"
                width={690}
                height={296} />
              <p className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="400">
                SPK Network, a decentralised, censorship-resistant social media protocol and incentivization layer for web3.
                The SPK Network provides the ability for video platforms and content creators to interact with the decentralized social graph, while rewarding infrastructure providers with SPK governance and BROCA gas tokens.
              </p>

              <div className="reveal-from-bottom" data-reveal-delay="600">
                <ButtonGroup>
                  <Button tag="a" color="primary" wideMobile href="https://peakd.com/hive/@spknetwork/spk-network-light-paper">
                    Light Paper
                  </Button>
                  <Button tag="a" color="dark" wideMobile href="https://hackmd.io/9dv5qjn6QcS2ip-oI_gSMA">
                    Tech Overview
                  </Button>
                  <Button tag="a" color="dark" wideMobile href="https://peakd.com/hive-112019/@spknetwork/spk-network-milestones-and-roadmap">
                    Roadmap
                  </Button>
                  <Button tag="a" color="dark" wideMobile href="https://peakd.com/hive-112019/@spknetwork/how-to-claim-larynx-step-by-step-tutorial">
                    How to claim
                  </Button>
                  <Button tag="a" color="dark" wideMobile href="https://spk.dlux.io/">
                    LARYNX DEX
                  </Button>
                </ButtonGroup>
                <hr />
                <ButtonGroup>
                  <Button tag="a" color="dark" wideMobile href="https://linktr.ee/spknetwork" style={{ height: "unset", whiteSpace: 'pre-wrap' }}>
                    Our linktree
                  </Button>
                  <Button tag="a" color="dark" wideMobile href="https://hackmd.io/WRmsHrZ5TgSz5w53QuQrgQ?view" style={{ height: "unset", whiteSpace: 'pre-wrap' }}>
                    SPK Network
                  </Button>
                  

                </ButtonGroup>
                <hr />
                <h6>
                  Mobile app
                </h6>
                <ButtonGroup>
                  <Button tag="a" color="dark" wideMobile href="https://play.google.com/store/apps/details?id=tv.threespeak.app" style={{ height: "unset", whiteSpace: 'pre-wrap' }}>
                    Android
                  </Button>
                  <Button tag="a" color="dark" wideMobile href="https://apps.apple.com/us/app/3speak/id1614771373" style={{ height: "unset", whiteSpace: 'pre-wrap' }}>
                    iOS
                  </Button>

                </ButtonGroup>
              </div>
            </div>
          </div>
          {/* <div className="hero-figure reveal-from-bottom illustration-element-01" data-reveal-value="20px" data-reveal-delay="800">
            <a
              data-video="https://player.vimeo.com/video/174002812"
              href="#0"
              aria-controls="video-modal"
              onClick={openModal}
            >
              <Image
                className="has-shadow"
                src={require('./../../assets/images/video-placeholder.jpg')}
                alt="Hero"
                width={896}
                height={504} />
            </a>
          </div> */}
          <Modal
            id="video-modal"
            show={videoModalActive}
            handleClose={closeModal}
            video="https://player.vimeo.com/video/174002812"
            videoTag="iframe" />
        </div>
      </div>
    </section>
  );
}

Hero.propTypes = propTypes;
Hero.defaultProps = defaultProps;

export default Hero;
