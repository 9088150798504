import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const propTypes = {
    ...SectionSplitProps.types
}

const defaultProps = {
    ...SectionSplitProps.defaults
}

const Mining = ({
    className,
    topOuterDivider,
    bottomOuterDivider,
    topDivider,
    bottomDivider,
    hasBgColor,
    invertColor,
    invertMobile,
    invertDesktop,
    alignTop,
    imageFill,
    ...props
}) => {

    const outerClasses = classNames(
        'features-split section',
        topOuterDivider && 'has-top-divider',
        bottomOuterDivider && 'has-bottom-divider',
        hasBgColor && 'has-bg-color',
        invertColor && 'invert-color',
        className
    );

    const innerClasses = classNames(
        'features-split-inner section-inner',
        topDivider && 'has-top-divider',
        bottomDivider && 'has-bottom-divider'
    );

    const splitClasses = classNames(
        'split-wrap',
        invertMobile && 'invert-mobile',
        invertDesktop && 'invert-desktop',
        alignTop && 'align-top'
    );

    const sectionHeader = {
        title: 'Independant monetization',
        paragraph: "Make community or content creator Memes into collectables with NFT's and issue them to the token holders who stake the most of a communities' or a creator's token.Users can earn collectable memes by staking the tokens of a creator or a community"
    };

    return (
        <section
            {...props}
            className={outerClasses}
        >
            <div className="container">
                <div className={innerClasses}>
                    <SectionHeader data={sectionHeader} className="center-content" />
                    <div className={splitClasses}>

                        <div className="split-item">
                            <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                                    SPK Network Elements
                                </div>
                                <h3 className="mt-0 mb-12">
                                    Service infrastructure Nodes
                                </h3>
                                <p>Encoding: <br />
                                    Node operators can provide encoding/transcoding services to the Network in exchange for direct payments from users taking advantage of their resources.<br />
                                    Content Storage Nodes: <br /> Dedicated Storage Node operators will be rewarded for providing storage capacity to the network. Validator nodes carry out the the process of validating these nodes for truth and consistently. 
                                    Content Delivery Network Gateways: <br />
                                    Users of the Network can set up a Content Delivery Gateway to the Network and receive rewards in doing so. They would effectively be running an IPFS gateway with some extra wrapping on top for blocklists and other functionality
                                </p>
                            </div>
                            <div className={
                                classNames(
                                    'split-item-image center-content-mobile reveal-from-bottom',
                                    imageFill && 'split-item-image-fill'
                                )}
                                data-reveal-container=".split-item">
                                <Image
                                    src={require('./../../assets/images/creator.png')}
                                    alt="Features split 01"
                                    width={328}
                                    height={196} />
                            </div>
                        </div>
                        <div className="split-item">
                            <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">

                                </div>
                                <h3 className="mt-0 mb-12">
                                    Service infrastructure Pool
                                </h3>
                                <p>
                                    The Service Infrastructure Pool (SIP) is a DeFi Liquidity Pool designed to hold funds indefinitely for funding infrastructure operation. 
                                    These funds would come from advertisements space and LARYNX miner purchases for example.
                                    The main SPK network SIP will help to cover a portion of the costs of the operation of the infrastructure node operators such as storage & validator nodes</p>
                            </div>
                            <div className={
                                classNames(
                                    'split-item-image center-content-mobile reveal-from-bottom',
                                    imageFill && 'split-item-image-fill'
                                )}
                                data-reveal-container=".split-item">
                                <Image
                                    src={require('./../../assets/505__TOKEN__What_Is_SIP____C.png')}
                                    alt="Features split 01"
                                    width={328}
                                    height={196} />
                            </div>
                        </div>
                        <div className="split-item">
                            <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                                    SPK Network Elements
                                </div>
                                <h3 className="mt-0 mb-12">
                                    WEB 3.0 SPK NETWORK
                                </h3>
                                <p>The SPK Network provides the ability for content platforms and content creators to interact with the decentralized social graph, while rewarding infrastructure providers with SPK governance and BROCA gas tokens.
                                    <br />
                                    Using blockchain technology to incentivize the storage of off-chain data. Most data will be off-chain, and we aim to be the first protocol to incentivize infrastructure providers of any kind to be rewarded for storing data using IPFS. You no longer need to rely solely on blockchain to store files; now, IPFS operation and beyond can be rewarded for joining the web 3 sharing economy.</p>
                            </div>
                            <div className={
                                classNames(
                                    'split-item-image center-content-mobile reveal-from-bottom',
                                    imageFill && 'split-item-image-fill'
                                )}
                                data-reveal-container=".split-item">
                                <Image
                                    src={require('./../../assets/images/100.png')}
                                    alt="Features split 01"
                                    width={328}
                                    height={196} />
                            </div>
                        </div>

                        {/*<div className="split-item">
                            <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">

                                </div>
                                <h3 className="mt-0 mb-12">
                                    NFT Memes act like rare, collectable football cards
                                </h3>
                                <p className="m-0">

                                    Users can earn collectable memes by staking the tokens of a creator or a community
                                    <br />
                                    Display your favorite NFTs on the frontend of your choice or profile
                                    <br />
                                    NFT Meme collectables will drive demand for the creator's tokens and create a new value capture mechanism, similar to how stocks capture value for investors, except this will be like the stock market for individuals and communities
                                    <br />
                                    Prove the authenticity of your collectable meme NTF by being able to verify that its original issuer was the creator themselves NFTs authenticity is attached to the original creator. NFTs will retain proof of who created them.
                                    <br />
                                    creators of an NFT will receive royalties every time the NFT is traded.   </p>
                            </div>
                            <div className={
                                classNames(
                                    'split-item-image center-content-mobile reveal-from-bottom',
                                    imageFill && 'split-item-image-fill'
                                )}
                                data-reveal-container=".split-item">
                                <Image
                                    src={require('./../../assets/images/stock.png')}
                                    alt="Features split 01"
                                    width={328}
                                    height={196} />
                            </div>
                                </div>*/}


                        <div className="split-item">
                            <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">

                                </div>
                                <h3 className="mt-0 mb-12">
                                    BROCA GAS TOKEN
                                </h3>
                                <p>The BROCA Gas token is akin to graphene Resource Credits in functionality which limits spam in the network . Staked SPK regenerates BROCA over time. 
                                    Broca then can be exchanged for services on the network</p>
                            </div>
                            <div className={
                                classNames(
                                    'split-item-image center-content-mobile reveal-from-bottom',
                                    imageFill && 'split-item-image-fill'
                                )}
                                data-reveal-container=".split-item">
                                <Image
                                    src={require('./../../assets/images/552.png')}
                                    alt="Features split 01"
                                    width={328}
                                    height={196} />
                            </div>
                        </div>

                        <div className="split-item">
                            <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">

                                </div>
                                <h3 className="mt-0 mb-12">
                                    SPEAK TOKEN
                                </h3>
                                <p>Using blockchain technology to incentivize the storage of off-chain data. 
                                    You no longer need to rely solely on blockchain to store files; now, you will be rewarded for operating an IPFS storage node or other service on the network</p>
                            </div>
                            <div className={
                                classNames(
                                    'split-item-image center-content-mobile reveal-from-bottom',
                                    imageFill && 'split-item-image-fill'
                                )}
                                data-reveal-container=".split-item">
                                <Image
                                    src={require('./../../assets/images/920.png')}
                                    alt="Features split 01"
                                    width={328}
                                    height={196} />
                            </div>
                        </div>

                        {/*<div className="split-item">
                            <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">

                                </div>
                                <h3 className="mt-0 mb-12">
                                    HIVE TOKEN
                                </h3>
                                <p>Content creators can create their own community tokens, allowing the community to operate outside of and independently from the “System” in a sustainable way.

                                </p>
                            </div>
                            <div className={
                                classNames(
                                    'split-item-image center-content-mobile reveal-from-bottom',
                                    imageFill && 'split-item-image-fill'
                                )}
                                data-reveal-container=".split-item">
                                <Image
                                    src={require('./../../assets/images/hivetoken.png')}
                                    alt="Features split 01"
                                    width={328}
                                    height={196} />
                            </div>
                        </div>

                        <div className="split-item">
                            <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">

                                </div>
                                <h3 className="mt-0 mb-12">
                                    PPY TOKEN
                                </h3>
                            </div>
                            <div className={
                                classNames(
                                    'split-item-image center-content-mobile reveal-from-bottom',
                                    imageFill && 'split-item-image-fill'
                                )}
                                data-reveal-container=".split-item">
                                <Image
                                    src={require('./../../assets/images/ppy.png')}
                                    alt="Features split 01"
                                    width={328}
                                    height={196} />
                            </div>
                                </div>*/}



                    </div>
                </div>
            </div>
        </section>
    );
}

Mining.propTypes = propTypes;
Mining.defaultProps = defaultProps;

export default Mining;