import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}

const Testimonial = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {

  const outerClasses = classNames(
    'testimonial section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'testimonial-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const tilesClasses = classNames(
    'tiles-wrap',
    pushLeft && 'push-left'
  );

  const sectionHeader = {
    title: 'EARN REWARDS FOR CONTRIBUTING TO THE NETWORK',
    paragraph: 'Content platforms, content creators or individual users can help the network by storing, validating, serving high throughput content or encoding videos and content. Participating in these activities allows individuals to earn mining rewards without the need for physical mining rigs'
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={tilesClasses}>

            <div className="tiles-item reveal-from-right" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="testimonial-item-content">
                  <p className="text-sm mb-0">
                    — Dedicated Storage Node operators will be rewarded for
                    providing storage capacity to the network. Validator node would do the process of validating
                    these nodes for truth and consistently.
                  </p>
                </div>
                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                  {/* <span className="testimonial-item-name text-color-high">Roman Level</span>
                  <span className="text-color-low"> / </span> */}
                  <span className="testimonial-item-link">
                    <a href="https://github.com/spknetwork/cdn-daemon">Content Storage Nodes</a>
                  </span>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="testimonial-item-content">
                  <p className="text-sm mb-0">
                    — Node operators provide video encoding / transcoding services to users on the network in exchange for direct payments for their work.
                  </p>
                </div>
                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                  <span className="testimonial-item-name text-color-high">Encoder</span>
                  <span className="text-color-low"> / </span>
                  <span className="testimonial-item-link">
                    <a href="https://github.com/spknetwork/video-encoder">Video Encoding Nodes</a>
                  </span>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-left" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="testimonial-item-content">
                  <p className="text-sm mb-0">
                    — Indexing nodes keep track of posts on the network and account metadata. These nodes play a crucial role in UX and performance of the network.

                    Indexing nodes keep track of posts on the network and account metadata. These nodes play a crucial role in UX and performance of the network. (Indexing nodes rewards mechanism is currently under construction).
                  </p>
                </div>
                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                  {/* <span className="testimonial-item-name text-color-high">Ben Stafford</span>
                  <span className="text-color-low"> / </span> */}
                  <span className="testimonial-item-link">
                    <a href="https://github.com/spknetwork/union-indexer-node">
                      Indexing Nodes</a>
                  </span>
                </div>
              </div>
            </div>
            <div className="tiles-item reveal-from-right" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="testimonial-item-content">
                  <p className="text-sm mb-0">
                    — SPK nodes providing infrastructure in the network will operate validator nodes that will independently execute validation checks to ensure storage provider nodes are being truthful and consistent. 
                    In doing so, validators will be rewarded for the task, and storage nodes will be rewarded as a result of proving they are storing what they say they are storing to the validator nodes using an adapted Proof of Access mining mechanism.
                    
                  </p>
                </div>
                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                  {/* <span className="testimonial-item-name text-color-high">Roman Level</span>
                  <span className="text-color-low"> / </span> */}
                  <span className="testimonial-item-link">
                    <a href="https://hackmd.io/WRmsHrZ5TgSz5w53QuQrgQ?view">Validator Nodes</a>
                  </span>
                </div>
              </div>
            </div>
            <div className="tiles-item reveal-from-right" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="testimonial-item-content">
                  <p className="text-sm mb-0">
                    — Users of the network can operate a Content Delivery Gateway. These nodes provide hot, high volume throughput for serving videos for possibly thousands of users at a time.
                    These play a crucial role in ensuring videos play well for the end users. 
                    Also rewarded through direct payments from content creators, platform operators & community DAO funds
                  </p>
                </div>
                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                  {/* <span className="testimonial-item-name text-color-high">Roman Level</span>
                  <span className="text-color-low"> / </span> */}
                  <span className="testimonial-item-link">
                    <a href="https://hackmd.io/WRmsHrZ5TgSz5w53QuQrgQ?view">Content Delivery Network Gateways</a>
                  </span>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
}

Testimonial.propTypes = propTypes;
Testimonial.defaultProps = defaultProps;

export default Testimonial;