import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const propTypes = {
    ...SectionSplitProps.types
}

const defaultProps = {
    ...SectionSplitProps.defaults
}

const FeaturesSplit1 = ({
    className,
    topOuterDivider,
    bottomOuterDivider,
    topDivider,
    bottomDivider,
    hasBgColor,
    invertColor,
    invertMobile,
    invertDesktop,
    alignTop,
    imageFill,
    ...props
}) => {

    const outerClasses = classNames(
        'features-split section',
        topOuterDivider && 'has-top-divider',
        bottomOuterDivider && 'has-bottom-divider',
        hasBgColor && 'has-bg-color',
        invertColor && 'invert-color',
        className
    );

    const innerClasses = classNames(
        'features-split-inner section-inner'
    );

    const splitClasses = classNames(
        'split-wrap',
        invertMobile && 'invert-mobile',
        invertDesktop && 'invert-desktop',
        alignTop && 'align-top'
    );

    const sectionHeader = {
        title: 'SPEAK INFRASTRUCTURE POOL(SIP)',
        paragraph: 'The SIP will be responsible for providing a lifetime liquidity pool for the SPK Network.'
    };

    return (
        <section
            {...props}
            className={outerClasses}
        >
            <div className="container">
                <div className={innerClasses}>
                    <SectionHeader data={sectionHeader} className="center-content" />
                    <div className={splitClasses}>

                        <div className="split-item">
                            <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">

                                </div>
                                <h3 className="mt-0 mb-12">
                                    SIP
                                </h3>
                                <p className="m-0">
                                    By default, all payments made into the SPK Network go to the SIP and locked in place. The funds locked in the SIP are then used to generate more money and value through Defi fee generation which is used to help subsidize the cost of operating the network at the infrastructure layer.

                                    Payments made through purchases of Larynx Miner tokens and advertisement rights will be sent to the SIP and locked, resulting in a continueous steady growth of the SIP pool.      </p>
                            </div>
                            <div className={
                                classNames(
                                    'split-item-image center-content-mobile reveal-from-bottom',
                                    imageFill && 'split-item-image-fill'
                                )}
                                data-reveal-container=".split-item">
                                <Image
                                    src={require('./../../assets/images/210.png')}
                                    alt="Features split 01"
                                    width={328}
                                    height={196} />
                            </div>
                        </div>

                        <div className="split-item">
                            <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
                                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">

                                </div>
                                <h3 className="mt-0 mb-12">
                                    SPEAK NETWORK TOKENS AND UTILITIES
                                </h3>
                                <p className="m-0">
                                    The Speak Network will support five tokens which will be used to complete transactions within the network systems.

                                    Each of these tokens will be used to provide incentives for servie node operators to provide services to the network and ensure the network runs smoothly     </p>
                            </div>
                            <div className={
                                classNames(
                                    'split-item-image center-content-mobile reveal-from-bottom',
                                    imageFill && 'split-item-image-fill'
                                )}
                                data-reveal-container=".split-item">
                                <Image
                                    src={require('./../../assets/images/510.png')}
                                    alt="Features split 02"
                                    width={528}
                                    height={396} />
                            </div>
                        </div>

                        <div className="split-item">
                            <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">

                                </div>
                                <h3 className="mt-0 mb-12">
                                    LARYNX MINER TOKEN
                                </h3>
                                <p className="m-0">
                                    In order to earn SPEAK tokens, miners need to burn Larynx miner tokens.

                                    The burned miner tokens will be used to provide infrastructure services to the network which will result in the rewarding of SPEAK tokens.

                                    You can get Larynx token by either<br />

                                    - Sending HIVE to the SIP<br />
                                    - Buying on the open market       </p>
                            </div>
                            <div className={
                                classNames(
                                    'split-item-image center-content-mobile reveal-from-bottom',
                                    imageFill && 'split-item-image-fill'
                                )}
                                data-reveal-container=".split-item">
                                <Image
                                    src={require('./../../assets/images/larynx.png')}
                                    alt="Features split 03"
                                    width={528}
                                    height={396} />
                            </div>

                        </div>
                        <div className="split-item">
                            <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">

                                </div>
                                <h3 className="mt-0 mb-12">
                                    BROCA GAS TOKEN
                                </h3>
                                <p className="m-0">
                                    The main objective when it come to the Broca token is the limitation of spam.

                                    Creators or platforms will be required to spend Broca tokens in order to upload prioritized content to the SPK Network. Users will still be able to upload content via IDX or supported secondary method outside of the hive blockchain.

                                    Broca tokens will regnerate for each user on a daily basis based upon the amount of liquid SPEAK tokens have been powered up on their account.   </p>
                            </div>
                            <div className={
                                classNames(
                                    'split-item-image center-content-mobile reveal-from-bottom',
                                    imageFill && 'split-item-image-fill'
                                )}
                                data-reveal-container=".split-item">
                                <Image
                                    src={require('./../../assets/images/borca.png')}
                                    alt="Features split 03"
                                    width={528}
                                    height={396} />
                            </div>

                        </div>

                        <div className="split-item">
                            <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">

                                </div>
                                <h3 className="mt-0 mb-12">
                                    SPEAK TOKEN
                                </h3>
                                <p className="m-0">

                                    Speak tokens will be used for governance by network users which will enable users to play governance roles such as stake based voting.

                                    SPEAK can also be staked into the SIP DeFi Pool in order to be eligible to receive trading and DeFi fees      </p>
                            </div>
                            <div className={
                                classNames(
                                    'split-item-image center-content-mobile reveal-from-bottom',
                                    imageFill && 'split-item-image-fill'
                                )}
                                data-reveal-container=".split-item">
                                <Image
                                    src={require('./../../assets/images/speak.png')}
                                    alt="Features split 03"
                                    width={528}
                                    height={396} />
                            </div>

                        </div>
                        <div className="split-item">
                            <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">

                                </div>
                                <h3 className="mt-0 mb-12">
                                    HIVE TOKEN
                                </h3>
                                <p className="m-0">
                                    Hive tokens will be used exclusively to purchase Ad Rights on the Network
                                    and LARYNX Miner Tokens which will be in turn used to mine SPEAK tokens.   </p>
                            </div>
                            <div className={
                                classNames(
                                    'split-item-image center-content-mobile reveal-from-bottom',
                                    imageFill && 'split-item-image-fill'
                                )}
                                data-reveal-container=".split-item">
                                <Image
                                    src={require('./../../assets/images/570.png')}
                                    alt="Features split 03"
                                    width={528}
                                    height={396} />
                            </div>

                        </div>

                        <div className="split-item">
                            <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">

                                </div>
                                <h3 className="mt-0 mb-12">
                                    PPY TOKEN
                                </h3>
                                <p className="m-0">
                                    PPY will be used as a liquidity token and be stored in the SIP to fund the SIP liquidity pool.

                                    A percentage of the Hive that is sent to the SIP will be converted to PPY and sent to the SPEAK-PPY liquidity pool.  </p>
                            </div>
                            <div className={
                                classNames(
                                    'split-item-image center-content-mobile reveal-from-bottom',
                                    imageFill && 'split-item-image-fill'
                                )}
                                data-reveal-container=".split-item">
                                <Image
                                    src={require('./../../assets/images/ppy.png')}
                                    alt="Features split 03"
                                    width={528}
                                    height={396} />
                            </div>

                        </div>

                        <div className="split-item">
                            <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">

                                </div>
                                <h3 className="mt-0 mb-12">
                                    NETWORK TOKEN UTILITIES
                                </h3>
                                <p className="m-0">
                                    SPK Network tokens offer you access to the following utilities<br />

                                    - Earn Mine SPEAK and regenerate more BROCA by burning LARYNX miner token and running Network Infrastructure Mill Nodes<br />

                                    - Spend BROCA to receive incentivized storage video encoding and storage resources.<br />

                                    - Transfer liquid BROCA in between accounts using a 3 second, feeless transfer system.<br />

                                    - Users who power up their SPEAK will be eleigible to receive a portion of the SIP DeFi fees as well as users who stake SPEAK to the SIP. <br />

                                    - Vote on network governance via staking SPEAK tokens. <br />

                                </p>
                            </div>
                            <div className={
                                classNames(
                                    'split-item-image center-content-mobile reveal-from-bottom',
                                    imageFill && 'split-item-image-fill'
                                )}
                                data-reveal-container=".split-item">
                                <Image
                                    src={require('./../../assets/images/world.svg')}
                                    alt="Features split 03"
                                    width={528}
                                    height={396} />
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </section>
    );
}

FeaturesSplit1.propTypes = propTypes;
FeaturesSplit1.defaultProps = defaultProps;

export default FeaturesSplit1;